<template>
  <div class="flex items-center justify-center">
    <NuxtLink to="/" class="inline-block pt-8 pb-4">
      <svg width="95" height="95" viewBox="0 0 126 126" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="63" cy="63" r="60.5" stroke="#E9B44C" stroke-width="5"/>
        <path d="M22.7909 66.9375C25.2126 66.9375 27.1758 65.0977 27.1758 62.8281C27.1758 60.5586 25.2126 58.7188 22.7909 58.7188C20.3692 58.7188 18.406 60.5586 18.406 62.8281C18.406 65.0977 20.3692 66.9375 22.7909 66.9375Z" fill="#E9B44C"/>
        <path d="M38.0177 66.9375C40.4394 66.9375 42.4026 65.0977 42.4026 62.8281C42.4026 60.5586 40.4394 58.7188 38.0177 58.7188C35.596 58.7188 33.6328 60.5586 33.6328 62.8281C33.6328 65.0977 35.596 66.9375 38.0177 66.9375Z" fill="#E9B44C"/>
        <path d="M103.791 66.9375C106.239 66.9375 108.224 65.0977 108.224 62.8281C108.224 60.5586 106.239 58.7188 103.791 58.7188C101.342 58.7188 99.3577 60.5586 99.3577 62.8281C99.3577 65.0977 101.342 66.9375 103.791 66.9375Z" fill="#E9B44C"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M92.9974 62.8281C92.9974 60.5601 91.0324 58.7188 88.6125 58.7188H53.1478C50.7278 58.7188 48.7629 60.5601 48.7629 62.8281C48.7629 65.0961 50.7278 66.9375 53.1478 66.9375H88.6125C91.0324 66.9375 92.9974 65.0961 92.9974 62.8281Z" fill="#E9B44C"/>
      </svg>
    </NuxtLink>
  </div>
</template>