<template>
  <v-list>
    <v-divider/>
    <v-list>
      <v-list-group>
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" prepend-icon="$mdiApi" title="Consultar"/>
        </template>
        <v-divider/>
        <v-list-item title="Intereses" to="/meta/interests"/>
        <v-divider/>
        <v-list-item title="Audiencias" to="/meta/audiences/request"/>
        <v-divider/>
      </v-list-group>
      <v-divider />
      <v-list-group>
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" prepend-icon="$mdiApi" title="Procesar"/>
        </template>
        <v-divider/>
        <v-list-item title="Intereses sugeridos" to="/meta/interests/suggestions"/>
        <v-divider/>
        <v-list-item title="Audiencias" to="/meta/audiences/process"/>
        <v-divider/>
      </v-list-group>
      <v-divider/>
      <v-list-group>
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" prepend-icon="$mdiApi" title="Población" disabled/>
        </template>
        <v-divider/>
        <v-list-item title="NIPA" to="/bea/NIPA"/>
        <v-divider/>
        <v-list-item title="NIPA Underlying Details" to="/bea/NIUnderlyingDetail"/>
        <v-divider/>
        <v-list-item title="Regional" to="/bea/Regional"/>
      </v-list-group>
      <v-divider/>
      <v-list-group>
        <template v-slot:activator="{ props }">
          <v-list-item v-bind="props" prepend-icon="$mdiApi" title="Finanzas" disabled/>
        </template>
      </v-list-group>
      <v-divider/>
    </v-list>
  </v-list>
</template>
<script setup lang="ts">
</script>